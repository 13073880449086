import SectionCard from "components/atoms/SectionCard";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import BilingualToggleContainer from "components/molecules/BilingualToggleContainer";
import DatePicker from "components/molecules/DatePicker";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import AddRemoveContactButtonAndModal from "components/organisms/contacts/AddContactButtonAndModal/AddRemoveContactButtonAndModal";
import RangeCheckboxes from "components/organisms/cosewic/RangeCheckboxes";
import {
  MAX_LENGTH_OF_20K,
  MAX_LENGTH_OF_5K,
  MAX_LENGTH_OF_8K,
} from "config/constants";
import {
  BilingualName,
  Maybe,
  PermitDescriptionAuthorityUsed,
  PermitDescriptionExplanationFor,
  PermitDescriptionPermitType,
  PermitDescriptionPurpose,
} from "generated/gql-types";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { htmlToPlainText } from "util/richtext";
import { PermitDescriptionIssuingAuthorityDropdownWithController } from "../PermitDescriptionIssuingAuthorityDropdown/PermitDescriptionIssuingAuthorityDropdown";
import { FederalParksMultiSelectListWithController } from "./FederalParksMultiSelectList/FederalParksMultiSelectWithController";
import { PermitDescriptionFormFields } from "./PermitDescriptionForm";

export interface PermitDescriptionInformationCardProps {
  initialValues: Partial<PermitDescriptionFormFields>;
  isFinalStage: boolean;
  isDmtsPermitDescription?: Maybe<boolean>;
}

export const PermitDescriptionInformationCard: React.FC<
  PermitDescriptionInformationCardProps
> = (props) => {
  const { initialValues, isFinalStage, isDmtsPermitDescription } = props;
  const { t, i18n } = useTranslation();

  const form = useFormContext<PermitDescriptionFormFields>();
  const { control, register, formState, watch } = form;
  const { errors } = formState;

  const validateHtmlTextMax5KLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_5K;

  const validateHtmlTextMax8KLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_8K;

  const validateHtmlTextMax20KLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_20K;

  const onRemoveContactOrOrg = async () => {
    form.setValue("customContact", null, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <SectionCard header={<h2>{t("permit_description_information")}</h2>}>
      {/* -------------- Permit type -------------- */}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp" disabled={!isDmtsPermitDescription}>
          <legend className="required">
            <span className="field-name">{t("permit_type")}</span>{" "}
            <strong className="required">({t("required")})</strong>
          </legend>

          {Object.values(PermitDescriptionPermitType).map((value) => {
            return (
              <label
                htmlFor={`radio-permit-type-${value}`}
                className="radio-inline"
                key={value}
              >
                <input
                  type="radio"
                  value={value}
                  id={`radio-permit-type-${value}`}
                  data-testid={`radio-permit-type-${value}`}
                  {...register("type", {
                    required: isDmtsPermitDescription!,
                  })}
                />
                &#160;{t(value)}
              </label>
            );
          })}
        </fieldset>
        {errors.type && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* -------------- Explanation for -------------- */}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp" disabled={!isDmtsPermitDescription}>
          <legend className="required">
            <span className="field-name">{t("explanation_for")}</span>{" "}
            <strong className="required">({t("required")})</strong>
          </legend>

          {Object.values(PermitDescriptionExplanationFor).map((value) => {
            return (
              <label
                htmlFor={`radio-explanation-for-${value}`}
                className="radio-inline"
                key={value}
              >
                <input
                  type="radio"
                  value={value}
                  id={`radio-explanation-for-${value}`}
                  data-testid={`radio-explanation-for-${value}`}
                  {...register("explanationFor", {
                    required: isDmtsPermitDescription!,
                  })}
                />
                &#160;{t(value)}
              </label>
            );
          })}
        </fieldset>
        {errors.explanationFor && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* -------------- Start date -------------- */}
      <div className="form-group">
        <Controller
          control={control}
          name="permitDates.fromDate"
          rules={{
            required:
              isFinalStage ||
              (watch("permitDates.fromDate") === "" &&
                watch("permitDates.toDate") !== ""),
          }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              id="dpk_permit_start_date"
              required={
                isDmtsPermitDescription! &&
                (isFinalStage ||
                  (watch("permitDates.fromDate") === "" &&
                    watch("permitDates.toDate") !== ""))
              }
              label={t("start_date")}
              value={value as string}
              onInput={onChange}
              className="width-230"
              fromController
              disabled={!isDmtsPermitDescription}
            />
          )}
        />
        {errors.permitDates?.fromDate && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* -------------- End date -------------- */}
      <div className="form-group">
        <Controller
          control={control}
          name="permitDates.toDate"
          rules={{
            required:
              isFinalStage ||
              (watch("permitDates.fromDate") !== "" &&
                watch("permitDates.toDate") === ""),
          }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              id="dpk_permit_end_date"
              required={
                isDmtsPermitDescription! &&
                (isFinalStage ||
                  (watch("permitDates.fromDate") !== "" &&
                    watch("permitDates.toDate") === ""))
              }
              label={t("end_date")}
              value={value as string}
              onInput={onChange}
              className="width-230"
              fromController
              disabled={!isDmtsPermitDescription}
            />
          )}
        />
        {errors.permitDates?.toDate && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* -------------- Purpose -------------- */}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp" disabled={!isDmtsPermitDescription}>
          <legend className={isFinalStage ? "required" : ""}>
            <span className="field-name">{t("purpose")}</span>{" "}
            {isFinalStage ? (
              <strong className="required"> ({t("required")})</strong>
            ) : null}
          </legend>
          {Object.values(PermitDescriptionPurpose).map((value) => {
            return (
              <div className="checkbox" key={value}>
                <label htmlFor={`checkbox-purpose-${value}`}>
                  <input
                    type="checkbox"
                    value={value}
                    id={`checkbox-purpose-${value}`}
                    data-testid={`checkbox-purpose-${value}`}
                    {...register("purposes", {
                      required: isFinalStage && isDmtsPermitDescription!,
                    })}
                  />{" "}
                  <span>{t(value)}</span>
                </label>
              </div>
            );
          })}
          {errors.purposes && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </fieldset>
      </div>
      {/* -------------- Description of activity -------------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="description-rte"
        showBoth={errors.description != null}
        english={
          <div className="form-group">
            <label
              htmlFor="description.english.text"
              className={isFinalStage ? "required" : ""}
            >
              {t("description_of_activity")}, {t("english")}
              {isFinalStage ? (
                <strong className="required"> ({t("required")})</strong>
              ) : null}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                required: isFinalStage && isDmtsPermitDescription!,
                validate: { maxLengthLimit: validateHtmlTextMax8KLengthLimit },
              }}
              defaultValue={initialValues.description?.english?.text ?? ""}
              id="description.english.text"
              name="description.english.text"
              disabled={!isDmtsPermitDescription}
            />
            {errors.description?.english &&
              (errors.description?.english as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.description?.english &&
              (errors.description.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_8K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label
              htmlFor="description.french.text"
              className={isFinalStage ? "required" : ""}
            >
              {t("description_of_activity")}, {t("french")}
              {isFinalStage ? (
                <strong className="required"> ({t("required")})</strong>
              ) : null}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                required: isFinalStage && isDmtsPermitDescription!,
                validate: { maxLengthLimit: validateHtmlTextMax8KLengthLimit },
              }}
              defaultValue={initialValues.description?.french?.text ?? ""}
              id="description.french.text"
              name="description.french.text"
              disabled={!isDmtsPermitDescription}
            />
            {errors.description?.french &&
              (errors.description?.french as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.description?.french &&
              (errors.description.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_8K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />
      {/* -------------- Pre-conditions -------------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="preConditions-rte"
        showBoth={errors.preConditions != null}
        english={
          <div className="form-group">
            <label
              htmlFor="preConditions.english.text"
              className={isFinalStage ? "required" : ""}
            >
              {t("pre_conditions")}, {t("english")}
              {isFinalStage ? (
                <strong className="required"> ({t("required")})</strong>
              ) : null}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                required: isFinalStage && isDmtsPermitDescription!,
                validate: {
                  maxLengthLimit: validateHtmlTextMax20KLengthLimit,
                },
              }}
              defaultValue={initialValues.preConditions?.english?.text ?? ""}
              id="preConditions.english.text"
              name="preConditions.english.text"
              disabled={!isDmtsPermitDescription}
            />
            {errors.preConditions?.english &&
              (errors.preConditions?.english as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.preConditions?.english &&
              (errors.preConditions.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_20K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label
              htmlFor="preConditions.french.text"
              className={isFinalStage ? "required" : ""}
            >
              {t("pre_conditions")}, {t("french")}
              {isFinalStage ? (
                <strong className="required"> ({t("required")})</strong>
              ) : null}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                required: isFinalStage && isDmtsPermitDescription!,
                validate: {
                  maxLengthLimit: validateHtmlTextMax20KLengthLimit,
                },
              }}
              defaultValue={initialValues.preConditions?.french?.text ?? ""}
              id="preConditions.french.text"
              name="preConditions.french.text"
              disabled={!isDmtsPermitDescription}
            />
            {errors.preConditions?.french &&
              (errors.preConditions?.french as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.preConditions?.french &&
              (errors.preConditions.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_20K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />
      {/* -------------- Authority used (Act of Parliament) -------------- */}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp" disabled={!isDmtsPermitDescription}>
          <legend className={isFinalStage ? "required" : ""}>
            <span className="field-name">
              {t("authority_used_act_of_parliament")}
            </span>{" "}
            {isFinalStage ? (
              <strong className="required"> ({t("required")})</strong>
            ) : null}
          </legend>
          <div className="checkbox-columns-lg">
            {Object.values(PermitDescriptionAuthorityUsed).map(
              (value, index) => {
                return (
                  <div className="checkbox" key={index}>
                    <label htmlFor={`checkbox-authority-used-${value}`}>
                      <input
                        type="checkbox"
                        value={value}
                        id={`checkbox-authority-used-${value}`}
                        data-testid={`checkbox-authority-used-${value}`}
                        {...register("authoritiesUsed", {
                          required: isFinalStage && isDmtsPermitDescription!,
                        })}
                      />
                      <span>{t(value)}</span>
                    </label>
                  </div>
                );
              }
            )}
          </div>
        </fieldset>
        {errors.authoritiesUsed && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Location of activity -------------- */}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp" disabled={!isDmtsPermitDescription}>
          <legend className={isFinalStage ? "required" : ""}>
            <span className="field-name">{t("location_of_activity")}</span>
            {isFinalStage ? (
              <strong className="required"> ({t("required")})</strong>
            ) : null}
          </legend>
          <Controller
            name="locationOfActivities"
            rules={{ required: isFinalStage && isDmtsPermitDescription! }}
            render={({ field: { onChange } }) => (
              <RangeCheckboxes
                onChange={onChange}
                defaultSelected={
                  (initialValues.locationOfActivities as DefinitelyGQL<BilingualName>[]) ??
                  []
                }
              />
            )}
          />
        </fieldset>
        {errors.locationOfActivities && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Terms and conditions -------------- */}
      {/* <BilingualToggleContainer
        className="form-group border-light"
        data-testid="termsAndConditions-rte"
        showBoth={errors.termsAndConditions != null}
        english={
          <div className="form-group">
            <label htmlFor="termsAndConditions.english.text">
              {t("terms_and_conditions")}, {t("english")}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                // required: isFinalStage,
                validate: {
                  maxLengthLimit: validateHtmlTextMax20KLengthLimit,
                },
              }}
              defaultValue={
                initialValues.termsAndConditions?.english?.text ?? ""
              }
              id="termsAndConditions.english.text"
              name="termsAndConditions.english.text"
            />
            {errors.termsAndConditions?.english &&
              (errors.termsAndConditions?.english as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.termsAndConditions?.english &&
              (errors.termsAndConditions.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_20K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="termsAndConditions.french.text">
              {t("terms_and_conditions")}, {t("french")}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                // required: isFinalStage,
                validate: {
                  maxLengthLimit: validateHtmlTextMax20KLengthLimit,
                },
              }}
              defaultValue={
                initialValues.termsAndConditions?.french?.text ?? ""
              }
              id="termsAndConditions.french.text"
              name="termsAndConditions.french.text"
            />
            {errors.termsAndConditions?.french &&
              (errors.termsAndConditions?.french as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.termsAndConditions?.french &&
              (errors.termsAndConditions.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_20K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      /> */}

      {/* -------------- Other relevant information -------------- */}
      <BilingualToggleContainer
        className="form-group border-light"
        data-testid="otherRelevantInformation-rte"
        showBoth={errors.otherRelevantInformation != null}
        english={
          <div className="form-group">
            <label htmlFor="otherRelevantInformation.english.text">
              {t("other_relevant_information")}, {t("english")}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                // required: isFinalStage,
                validate: { maxLengthLimit: validateHtmlTextMax5KLengthLimit },
              }}
              defaultValue={
                initialValues.otherRelevantInformation?.english?.text ?? ""
              }
              id="otherRelevantInformation.english.text"
              name="otherRelevantInformation.english.text"
              disabled={!isDmtsPermitDescription}
            />
            {errors.otherRelevantInformation?.english &&
              (errors.otherRelevantInformation?.english as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.otherRelevantInformation?.english &&
              (errors.otherRelevantInformation.english as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_5K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
        french={
          <div className="form-group">
            <label htmlFor="otherRelevantInformation.french.text">
              {t("other_relevant_information")}, {t("french")}
            </label>
            <FullHTMLEditorWithController
              control={control}
              rules={{
                // required: isFinalStage,
                validate: { maxLengthLimit: validateHtmlTextMax5KLengthLimit },
              }}
              defaultValue={
                initialValues.otherRelevantInformation?.french?.text ?? ""
              }
              id="otherRelevantInformation.french.text"
              name="otherRelevantInformation.french.text"
              disabled={!isDmtsPermitDescription}
            />
            {errors.otherRelevantInformation?.french &&
              (errors.otherRelevantInformation?.french as any)?.text?.type ===
                "required" && (
                <FieldValidationError>
                  {t("field_is_required")}
                </FieldValidationError>
              )}
            {errors.otherRelevantInformation?.french &&
              (errors.otherRelevantInformation.french as any)?.text?.type ===
                "maxLengthLimit" && (
                <FieldValidationError>
                  {t("reach_the_max_length", {
                    count: MAX_LENGTH_OF_5K,
                  })}
                </FieldValidationError>
              )}
          </div>
        }
      />

      {/* -------------- Federal Canada places -------------- */}
      <div className="form-group">
        <FederalParksMultiSelectListWithController
          control={control}
          name="federalCanadaPlaces"
          disabled={!isDmtsPermitDescription}
        />
      </div>

      {/* -------------------- Issuing authority --------------------- */}
      <div className="form-group">
        <label htmlFor={`select-issuing-authority-${0}`} className={"required"}>
          {t("issuing_authority")}
          <strong className="required"> ({t("required")})</strong>
        </label>
        <div>
          <PermitDescriptionIssuingAuthorityDropdownWithController
            control={control}
            name={"issuingAuthority"}
            id={"select-issuing-authority"}
            placeholder={t("select_an_issuing_authority")}
            rules={{ required: isDmtsPermitDescription! }}
            disabled={!isDmtsPermitDescription}
          />
        </div>
        {errors.issuingAuthority && (errors.issuingAuthority as any) && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Contact -------------------------- */}
      <div className="form-group">
        <label htmlFor="ContactInput" className="required">
          {t("contact")}
          <strong className="required"> ({t("required")})</strong>
        </label>
        <Controller
          name="customContact"
          control={control}
          rules={{ validate: (value) => value != null }}
          render={({ field: { value, onChange } }) => {
            return (
              <AddRemoveContactButtonAndModal
                id={"ContactInput"}
                contact={value}
                onSave={(newValue, contactType) => {
                  if (contactType === "contact") {
                    onChange({
                      contactType,
                      contactRef: newValue,
                      organizationRef: null,
                    });
                  } else if (contactType === "organization") {
                    onChange({
                      contactType,
                      contactRef: null,
                      organizationRef: newValue,
                    });
                  }
                  form.trigger("customContact");
                }}
                onRemove={onRemoveContactOrOrg}
              />
            );
          }}
        />
        {errors.customContact != null && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>

      {/* -------------- Note -------------- */}
      <div className="form-group">
        <label htmlFor="note">
          <span className="field-name">{t("note")}</span>{" "}
        </label>
        <FullHTMLEditorWithController
          control={control}
          rules={{
            validate: { maxLengthLimit: validateHtmlTextMax5KLengthLimit },
          }}
          defaultValue={initialValues?.note?.text ?? ""}
          id="note_text"
          name="note.text"
        />
        {errors.note && (errors.note as any)?.text?.type === "maxLengthLimit" && (
          <FieldValidationError>
            {t("reach_the_max_length", {
              count: MAX_LENGTH_OF_5K,
            })}
          </FieldValidationError>
        )}
      </div>
    </SectionCard>
  );
};
